<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Incident Filter</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- Filter Inputs -->
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Start Date</label>
                  <input
                    v-model="filters.start_date"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>End Date</label>
                  <input
                    v-model="filters.end_date"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Start Time</label>
                  <input
                    v-model="filters.start_time"
                    type="time"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>End Time</label>
                  <input
                    v-model="filters.end_time"
                    type="time"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Incident ID</label>
                  <input
                    v-model="filters.incident_id"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Status</label>
                  <select v-model="filters.status" class="form-control">
                    <option value="">Select Status</option>
                    <option v-for="status in statuses" :value="status">
                      {{ status }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label>Site</label>
                  <Multiselect
                    v-model="filters.site"
                    :options="sites"
                    :multiple="false"
                    :closeOnSelect="true"
                    :clearOnSelect="false"
                    :hideSelected="true"
                    :preserveSearch="true"
                    placeholder="Select Site"
                    label="name"
                    track-by="id"
                  ></Multiselect>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-between my-3">
                  <button
                    :disabled="loading"
                    class="btn btn-primary btn-sm flex-grow-1 me-2"
                    @click="getIncidents(1)"
                  >
                    Search
                  </button>
                  <button
                    :disabled="loading"
                    class="btn btn-primary btn-sm flex-grow-1"
                    @click="clearFilters"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <Pagination :config="pagination" @search="getIncidents" />
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Person</th>
                <th>Types</th>
                <th>Description</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="incident of incidents"
                :key="'incident-' + incident.id"
              >
                <td class="col-id">{{ incident.id }}</td>
                <td class="col-person">
                  <img
                    v-viewer
                    v-if="incident.faces[0]?.url"
                    :src="incident.faces[0].url"
                    class="img-fluid"
                    width="150"
                    height="auto"
                    alt="Person Image"
                    @contextmenu.prevent
                  />
                </td>
                <td class="col-types">
                  {{ incident.types?.join(", ") }}
                </td>
                <td class="description-column">
                  {{ incident.description }}
                </td>
                <td class="col-status">
                  {{ incident.status }}
                </td>
                <td class="col-actions">
                  <button
                    class="btn btn-sm btn-primary"
                    @click="
                      $router.push(
                        `/faces/incidents/${incident.id}?site_id=${incident.site_id}`
                      )
                    "
                  >
                    Manage
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="6" v-if="loading" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </td>
                <td
                  colspan="6"
                  v-if="!loading && incidents.length === 0"
                  class="text-center"
                >
                  No incidents found.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination :config="pagination" @search="getIncidents" />
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import Pagination from "../../components/Pagination.vue";
import axios from "axios";
import authHeader from "../../services/auth-header";
import ClientService from "../../services/client.service";

export default {
  name: "Incidents",
  components: {
    Multiselect,
    Pagination,
  },
  data() {
    return {
      incidents: [],
      loading: false,
      sites: [],
      statuses: ["Pending", "Approved", "Rejected", "Closed"],
      filters: {
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        incident_id: "",
        status: "",
        site: null,
      },
      pagination: {
        page: 1,
        showing: 25,
        maxPage: 1,
        total: 0,
      },
    };
  },
  mounted() {
    this.loadSites();
    this.disableImageContextMenu();
  },
  methods: {
    disableImageContextMenu() {
      document.querySelectorAll("img").forEach((img) => {
        img.addEventListener("contextmenu", (e) => e.preventDefault());
      });
    },
    getIncidents(page = 1) {
      if (this.loading) return;
      this.loading = true;

      if (!this.filters.site?.id) {
        this.$error("Please select a site!");
        this.loading = false;
        return;
      }

      let params = {
        page: page,
        site_id: this.filters.site ? this.filters.site.id : null,
      };

      if (this.filters.start_date) params.start = this.filters.start_date;
      if (this.filters.end_date) params.end = this.filters.end_date;
      if (this.filters.start_time) params.start_time = this.filters.start_time;
      if (this.filters.end_time) params.end_time = this.filters.end_time;
      if (this.filters.incident_id)
        params.incident_id = this.filters.incident_id;
      if (this.filters.status) params.status = this.filters.status;

      axios
        .get(`https://api.varsanpr.com/customer/incident/incidents`, {
          params,
          headers: authHeader(),
        })
        .then((response) => {
          this.incidents = response.data.incidents;
          this.pagination = {
            page: page,
            maxPage:
              response.data.pagination.total > 0
                ? Math.ceil(
                    response.data.pagination.total / this.pagination.showing
                  )
                : 1,
            total: response.data.pagination.total,
            showing: this.pagination.showing,
          };
        })
        .catch((error) => {
          this.$error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilters() {
      this.filters = {
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        incident_id: "",
        status: "",
        site: this.filters.site,
      };
      this.getIncidents();
    },
    async loadSites() {
      const response = await ClientService.getClientSites(
        this.$store.state.auth.user.selectedClient,
        null,
        null,
        5
      );
      this.sites = response.data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      if (this.sites.length > 0) {
        this.filters.site = this.sites[0];
        this.getIncidents();
      }
    },
  },
};
</script>

<style scoped>
.table th,
.table td {
  white-space: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-column {
  max-width: 300px;
  white-space: normal;
  word-wrap: wrap;
}

.col-id {
  width: 80px;
}
.col-person {
  width: 100px;
}
.col-types {
  width: 120px;
}
.col-status {
  width: 100px;
}
.col-actions {
  width: 100px;
}
</style>
